
<template>
  <div>
    <vab-query-form style="padding-top: 20px">
      <vab-query-form-right-panel :span="24">
        <el-form :inline="true" :model="queryForm" @submit.prevent>
          <el-form-item label="展览名称">
            <el-input placeholder="请输入展览名称" v-model="queryForm.meetingName"></el-input>
          </el-form-item>
          <el-form-item label="主办名称" style="margin: 0 10px 10px 10px !important">
            <el-input placeholder="请输入主办名称" v-model="queryForm.hostName"></el-input>
          </el-form-item>

          <el-form-item label="主办手机号" style="margin: 0 10px 10px 10px !important">
            <el-input placeholder="请输入主办手机号" v-model="queryForm.phone"></el-input>
          </el-form-item>

          <!-- <el-form-item label="申请类型">
            <el-select
              v-model="queryForm.type"
              class="m-2"
              placeholder="请选择申请类型"
            >
              <el-option
                v-for="item in typeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item> -->

          <el-form-item>
            <el-button :icon="Search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>
    <el-table v-loading="listLoading" border :data="list">
      <el-table-column label="展览名称" prop="meetingName" show-overflow-tooltip />
      <el-table-column label="主办名称" prop="hostName" show-overflow-tooltip />
      <el-table-column label="主办手机号" prop="hostPhone" show-overflow-tooltip />
      <el-table-column label="事件ID" prop="eventId" show-overflow-tooltip />

      <!-- <el-table-column label="预览二维码" prop="businessLicense">
        <template #default="{ row }">
          <el-image
            :src="row.qrcodeUrl"
            style="width: 100px; height: 100px"
            :preview-src-list="[row.qrcodeUrl]"
            fit="cover"
          ></el-image>
        </template>
      </el-table-column> -->

      <el-table-column label="状态" prop="status">
        <template #default="{ row }">
          <el-tag type="success" v-if="row.status == 'PUBLISHED'">
            已发布
          </el-tag>
          <el-tag type="danger" v-if="row.status == 'NO_PASS'">
            审核被驳回
          </el-tag>
          <el-tag type="warning" v-if="row.status == 'UNDER_REVIEW'">
            审核中
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column label="操作" show-overflow-tooltip>
        <template #default="{ row }">
          <el-button type="primary" @click="handleEdit(row)">
            <vab-icon icon="edit-2-line" />
            审核
          </el-button>
          <el-button type="primary" @click="handleQrcode(row)">
            预览二维码
          </el-button>
          <!-- <el-button type="danger" @click="handleDelete(row)">
            <vab-icon icon="delete-bin-6-line" />
            删除
          </el-button> -->
        </template>
      </el-table-column>
      <template #empty>
        <!-- <el-image
                class="vab-data-empty"
                :src="require('@/assets/empty_images/data_empty.png')"
              /> -->
        <el-empty class="vab-data-empty" description="暂无数据" />
      </template>
    </el-table>
    <el-pagination background :current-page="queryForm.pageNumber" :layout="layout" :page-size="queryForm.pageSize" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />

    <el-dialog title="二维码" v-model="dialogVisible" width="30%">
      <template v-for='(item, index) in qrcodeUrlList'>
        <el-image :src='item.qrcodeUrl' style="margin:0 auto;"></el-image>
        <p style="text-align:center">{{item.appKey}}</p>
      </template>
    </el-dialog>

    <edit ref="editRef" @fetch-data="fetchData" />
  </div>
</template>

<script>
import {
  defineComponent,
  defineAsyncComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
  onActivated,
} from 'vue'

import { Delete, Plus, Search } from '@element-plus/icons'
import { GetexhibitionList } from '@/api/hostApply'
import { getQrcodeUrls } from '@/api/exhibitionManage'
export default defineComponent({
  name: 'ticketList',
  components: {
    Edit: defineAsyncComponent(() => import('./components/editHost')),
  },
  setup() {
    const { proxy } = getCurrentInstance()

    const state = reactive({
      queryForm: { name: '', pageNum: 1, pageSize: 20, phone: '', type: '' },
      total: 0,
      shopList: [],
      listLoading: false,
      dialogVisible: false,
      list: [],
      qrcodeUrlList: [],
      layout: 'total, sizes, prev, pager, next, jumper',
      editRef: null,
      typeOptions: [
        {
          label: '全部',
          value: '',
        },
        {
          label: '申请会议',
          value: 'HOLD_MEETING',
        },
        {
          label: '申请展览',
          value: 'EXHIBITION',
        },
      ],
    })

    const handleEdit = (row) => {
      if (row.code) {
        state.editRef.showEdit(row)
      }
    }

    const handleDelete = (row) => {
      if (row.id) {
        proxy.$baseConfirm('你确定要删除当前项吗', null, async () => {
          const { msg } = await delCoupon(row.id)
          proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
          await fetchData()
        })
      }
    }

    const queryData = () => {
      fetchData()
    }

    const fetchData = async () => {
      state.listLoading = true
      const { data } = await GetexhibitionList(state.queryForm)
      console.log(data, '1123123')
      state.list = data.data
      state.total = data.total
      state.listLoading = false
    }

    const handleQrcode = (row) => {
      getQrcodeUrls(row.code, 'HOLD_EXHIBITION').then((res) => {
        console.log(res)
        state.qrcodeUrlList = res.data
        state.dialogVisible = true
      })
    }

    const handleSizeChange = (val) => {
      state.queryForm.pageSize = val
      queryData()
    }
    const handleCurrentChange = (val) => {
      state.queryForm.pageNum = val
      queryData()
    }

    onActivated(() => {
      queryData()
    })
    return {
      ...toRefs(state),
      handleEdit,
      queryData,
      fetchData,
      handleSizeChange,
      handleCurrentChange,
      handleDelete,
      Delete,
      Search,
      Plus,
      handleQrcode,
    }
  },
})
</script>
<style lang="scss" scoped></style>
